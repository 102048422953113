import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";

let speakers = {

  fetchDashboardSpeakersGuestsCancelToken: axios.CancelToken.source(),

  fetchDashboardSpeakersGuests: (ctx) => {
    speakers.fetchDashboardSpeakersGuestsCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/speakers/fetchDashboardGuests",
          data: { ctx: ctx },
          method: "POST",
        },
        { cancelToken: speakers.fetchDashboardSpeakersGuestsCancelToken }
      )
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
            reviewCount : resp.data.responseData.reviewCount,
            todayOutcomes : resp.data.responseData.todayOutcomes,
            monthOutcomes : resp.data.responseData.monthOutcomes,
            monthPayed : resp.data.responseData.monthPayed
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchSpeakersGuestsCancelToken: axios.CancelToken.source(),

  fetchSpeakersGuests: (ctx) => {
    speakers.fetchSpeakersGuestsCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/speakers/fetchGuests",
          data: { ctx: ctx },
          method: "POST",
        },
        { cancelToken: speakers.fetchSpeakersGuestsCancelToken }
      )
        .then((resp) => {
          resolve({
            items: resp.data.responseData.guests,
            totalCount: resp.data.responseData.count,
            statistics : resp.data.responseData.statistics
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  fetchSpeakersStatisticsCancelToken: axios.CancelToken.source(),

  fetchSpeakersStatistics: (ctx) => {
    speakers.fetchSpeakersStatisticsCancelToken.cancel();
    return new Promise((resolve, reject) => {
      axios(
        {
          url: config.apiEndPoint + "/speakers/fetchSpeakersStatistics",
          data: { ctx: ctx },
          method: "POST",
        },
        { cancelToken: speakers.fetchSpeakersStatisticsCancelToken }
      )
        .then((resp) => {
          resolve({
            items: resp.data.responseData.speakers,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject({});
          } else reject(err);
        });
    });
  },

  downloadSpeakersExcelStatistics: (locale, filters) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/speakers/downloadExcelStatistics?locale=" +
          encodeURIComponent(locale) +
          "&token=" +
          encodeURIComponent(token) +
          "&filters=" +
          encodeURIComponent(JSON.stringify(filters))
      );
      link.setAttribute("target", "_blank");
      let filename = "speakers_excel_statistics_export.xlsx";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

};

export default speakers;
