<template>
  <div>
    <v-progress-circular
      v-if="loadingFilters && showLoader"
      :indeterminate="true"
      :rotate="0"
      :size="32"
      :width="4"
      color="light-blue"
    ></v-progress-circular>
    <div v-if="!loadingFilters ">
      <v-row dense>
        <v-col
          v-if="
            FinancialDashboardFilter &&
              (!$store.state.user.locked_type ||
                $store.state.user.locked_type.length < 1)
          "
        >
          <v-select
            class="minfield"
            outlined
            dense
            :label="$t('filters-type-hint')"
            v-model="filters.filterByType"
            :items="flt_types"
            item-text="name"
            item-value="id"
            :disabled="disabled"
          >
          </v-select>
        </v-col>

        <v-col
          v-if="
            FinancialDashboardFilter &&
              (!$store.state.user.locked_radio ||
                $store.state.user.locked_radio.length < 1)
          "
        >
          <v-select
            class="minfield"
            outlined
            dense
            :label="$t('filters-scheduler-hint')"
            v-model="filters.filterByScheduler"
            :items="flt_schedulers"
            item-text="name"
            item-value="id"
            :disabled="disabled"
          >
          </v-select>
        </v-col>

        <v-col
          v-if="
            FinancialDashboardFilter &&
              (!$store.state.user.locked_radio ||
                $store.state.user.locked_radio.length < 1)
          "
        >
          <v-select
            class="minfield"
            outlined
            dense
            :label="$t('filters-radio-hint')"
            v-model="filters.filterByRadio"
            :items="flt_radios"
            item-text="name"
            item-value="id"
            :disabled="disabled"
          >
          </v-select>
        </v-col>
        <v-col v-if="FinancialDashboardFilter">
          <v-select
            class="minfield"
            outlined
            dense
            :label="$t('filters-period-hint')"
            ref="period"
            v-model="filters.filterByPeriod"
            :items="getPeriodFilters"
            item-text="name"
            item-value="id"
            :disabled="disabled"
          >
          </v-select>
        </v-col>
        <v-col v-if="FinancialDashboardFilter && filters.filterByPeriod == 9">
          <v-dialog
            ref="dialog_start_date"
            v-model="date_start_modal"
            :return-value.sync="filters.filterByCustomDateStart"
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                outlined
                v-model="start_date_formatted"
                :disabled="disabled"
                :label="$t('filters-startdate-hint')"
                readonly
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="
                $refs.dialog_start_date.save(filters.filterByCustomDateStart)
              "
              v-model="filters.filterByCustomDateStart"
              scrollable
            >
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col v-if="FinancialDashboardFilter && filters.filterByPeriod == 9">
          <v-dialog
            ref="dialog_end_date"
            v-model="date_end_modal"
            :return-value.sync="filters.filterByCustomDateEnd"
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                outlined
                v-model="end_date_formatted"
                :disabled="disabled"
                :label="$t('filters-enddate-hint')"
                readonly
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="$refs.dialog_end_date.save(filters.filterByCustomDateEnd)"
              v-model="filters.filterByCustomDateEnd"
              scrollable
            >
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.minfield {
  min-width: 100px;
}
</style>

<script>
import staticData from "../apis/static";

export default {
  data() {
    return {
      loadingFilters: false,
      date_start_modal: false,
      date_end_modal: false,
      filtersChanged: false,
      flt_radios: [],
      flt_schedulers: [],
      flt_types: [],
    };
  },

  mounted() {
    this.fetchFilters();
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
    },

    getPeriodFilters: {
      get: function() {
        return staticData.filtersPeriods();
      },
    },
  },

  methods: {
    onFiltersLoading: function() {
      this.$emit("onFiltersLoading");
    },

    onFiltersLoaded: function() {
      this.$emit("onFiltersLoaded");
    },

    fetchFilters: function() {
      this.filtersChanged = true;
      this.loadingFilters = true;
      this.onFiltersLoading();
      try {
        if (this.$store.state.types) {
          this.flt_types = [
            { id: null, name: this.$t("gbl-all-female") },
            ...this.$store.state.types,
          ];
        } else {
          this.flt_types = [{ id: null, name: this.$t("gbl-all-female") }];
        }

        if (this.$store.state.radios) {
            this.flt_radios = [
          { id: null, name: this.$t("gbl-all-female") },
          ...this.$store.state.radios,
        ];
        } else {
           this.flt_radios = [
          { id: null, name: this.$t("gbl-all-female") }];
        }
         
         if (this.$store.state.schedulers) {
             this.flt_schedulers = [
          { id: null, name: this.$t("gbl-all-male") },
          ...this.$store.state.schedulers,
        ];
         } else {
           this.flt_schedulers = [
          { id: null, name: this.$t("gbl-all-male") }];
         }
      
      } catch (err) {
        console.log(err);
      }

      this.onFiltersLoaded();
      this.loadingFilters = false;
    },
  },

  watch: {
    "filters.filterByPeriod": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      },
    },

    "filters.filterByCustomDateStart": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      },
    },

    "filters.filterByCustomDateEnd": {
      handler() {
        if (!this.filtersChanged) {
          this.filtersChanged = true;

          this.filtersChanged = false;
        }
      },
    },
  },
  props: ["disabled", "filters", "showLoader", "FinancialDashboardFilter"],
};
</script>
