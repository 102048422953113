import axios from "axios";
import config from "../config";

let dashboard = {
  fetchFinancialDashboard: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/dashboard/financial",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({ items: resp.data.responseData.dashboard });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchTwilioBalance: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/dashboard/twilioBalance",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData.twilioBalance);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default dashboard;
