<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <FiltersBar
          :filters="filters"
          :disabled="loadingDashboard"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :FinancialDashboardFilter="true"
        />
        <v-progress-circular
          v-if="loadingDashboard"
          :indeterminate="true"
          :rotate="0"
          :size="32"
          :width="4"
          color="light-blue"
        />

        <v-row >
          <v-col cols="12" sm="12" lg="12">
            <base-material-stats-card 
              color="success"
              icon="mdi-currency-usd-circle-outline"
              :title="$t('dashboard-twilio-balance')"
              :value="computeTwilioBalance"
              sub-icon="mdi-calendar"
              sub-text="Twilio Balance (WA / VOIP)"
            />
          </v-col>
        </v-row>
        <div v-if="!loadingDashboard">
          <v-row>
            <v-col cols="12" sm="6" :lg="computeStatsCols">
              <base-material-stats-card
                color="primary"
                icon="mdi-video-account"
                :title="$t('dashboard-ceo-total-interviews')"
                :value="computeTotalInterviews"
                sub-icon="mdi-calendar"
                :sub-text="filterByPeriodText"
              />
            </v-col>
            <v-col cols="12" sm="6" :lg="computeStatsCols">
              <base-material-stats-card
                color="orange"
                icon="mdi-cart"
                :title="$t('dashboard-ceo-sold-interviews')"
                :value="computeSoldInterviews"
                sub-icon="mdi-calendar"
                :sub-text="filterByPeriodText"
              />
            </v-col>
            <v-col cols="12" sm="6" :lg="computeStatsCols">
              <base-material-stats-card
                color="primary"
                icon="mdi-car-turbocharger"
                :title="$t('dashboard-ceo-sold-ratio')"
                :value="computeSoldRatio"
                sub-icon="mdi-calendar"
                :sub-text="filterByPeriodText"
              />
            </v-col>
            <v-col
              v-if="$store.state.hasRight('56')"
              cols="12"
              sm="6"
              :lg="computeStatsCols"
            >
              <base-material-stats-card
                color="success"
                icon="mdi-currency-eur"
                :title="$t('dashboard-ceo-gross-value')"
                :value="computeGrossValue"
                sub-icon="mdi-calendar"
                :sub-text="filterByPeriodText"
              />
            </v-col>
          </v-row>

          

          <div
            v-if="
              filters &&
                (filters.filterByType == 1 || filters.filterByType == null) &&
                $store.state.hasRight('121') &&
                (!$store.state.user.locked_type ||
                  $store.state.user.locked_type.length < 1 ||
                  $store.state.user.locked_type.includes(1))
            "
          >
            <v-toolbar color="primary">
              <v-toolbar-title class="white--text">{{
                $t("dashboard-ceo-title-live")
              }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-icon dark large>mdi-chart-pie</v-icon>
            </v-toolbar>

            <v-row>
              <v-col cols="12" lg="4" sm="6">
                <base-material-card
                  id="live_pie"
                  color="primary"
                  icon="mdi-chart-pie"
                  :title="$t('dashboard-ceo-products-sales')"
                  class="px-4 py-3 chart-title"
                >
                  <chartist
                    v-if="computeSoldInterviews"
                    class="sales-chart"
                    :data="live_pie.data"
                    :options="live_pie.options"
                    type="Pie"
                    style="max-height: 250px;"
                  />

                  <div v-if="!computeSoldInterviews" style="max-height: 250px;">
                    Nessuna vendita
                  </div>

                  <v-divider class="ma-3" />

                  <div class="px-3">
                    <div
                      class="body-2 text-uppercase grey--text font-weight-bold mb-3"
                    >
                      Legenda
                    </div>

                    <v-row align="center" class="ma-0">
                      <v-avatar class="mr-1" color="#2196fc" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-single")
                      }}</span>
                      <v-avatar class="mr-1" color="#ffc107" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-triple")
                      }}</span>
                      <v-avatar class="mr-1" color="#F44336" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-allsocial")
                      }}</span>
                    </v-row>
                  </div>
                </base-material-card>
              </v-col>

              <v-col cols="12" lg="4" sm="6">
                <base-material-card
                  id="live_pie"
                  color="orange"
                  icon="mdi-chart-bar"
                  :title="$t('dashboard-ceo-products-sales-bar')"
                  class="px-4 py-3 chart-title"
                >
                  <chartist
                    v-if="computeSoldInterviews"
                    class="sales-bar-chart"
                    :data="live_bar.data"
                    :options="live_bar.options"
                    type="Bar"
                    style="max-height: 250px;"
                  />

                  <div v-if="!computeSoldInterviews" style="max-height: 250px;">
                    Nessuna vendita
                  </div>

                  <v-divider class="ma-3" />

                  <div class="px-3">
                    <div
                      class="body-2 text-uppercase grey--text font-weight-bold mb-3"
                    >
                      Legenda
                    </div>

                    <v-row align="center" class="ma-0">
                      <v-avatar class="mr-1" color="#2196fc" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-single")
                      }}</span>
                      <v-avatar class="mr-1" color="#ffc107" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-triple")
                      }}</span>
                      <v-avatar class="mr-1" color="#F44336" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-allsocial")
                      }}</span>
                    </v-row>
                  </div>
                </base-material-card>
              </v-col>
              <v-col cols="12" lg="4" sm="6">
                <base-material-card
                  id="live_pie"
                  color="orange"
                  icon="mdi-chart-bar"
                  :title="$t('dashboard-ceo-products-sales-numbers')"
                  class="px-4 py-3 chart-title"
                >
                  <chartist
                    v-if="computeSoldInterviews"
                    class="sales-bar-chart"
                    :data="live_barn.data"
                    :options="live_barn.options"
                    type="Bar"
                    style="max-height: 250px;"
                  />

                  <div v-if="!computeSoldInterviews" style="max-height: 250px;">
                    Nessuna vendita
                  </div>

                  <v-divider class="ma-3" />

                  <div class="px-3">
                    <div
                      class="body-2 text-uppercase grey--text font-weight-bold mb-3"
                    >
                      Legenda
                    </div>

                    <v-row align="center" class="ma-0">
                      <v-avatar class="mr-1" color="#2196fc" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-single")
                      }}</span>
                      <v-avatar class="mr-1" color="#ffc107" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-triple")
                      }}</span>
                      <v-avatar class="mr-1" color="#F44336" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-allsocial")
                      }}</span>
                    </v-row>
                  </div>
                </base-material-card>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="
              filters &&
                (filters.filterByType == 2 || filters.filterByType == null) &&
                $store.state.hasRight('121') &&
                (!$store.state.user.locked_type ||
                  $store.state.user.locked_type.length < 1 ||
                  $store.state.user.locked_type.includes(2))
            "
          >
            <v-toolbar color="secondary">
              <v-toolbar-title class="white--text">{{
                $t("dashboard-ceo-title-videocall")
              }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-icon dark large>mdi-chart-pie</v-icon>
            </v-toolbar>

            <v-row>
              <v-col cols="12" lg="4" sm="6">
                <base-material-card
                  id="vc_pie"
                  color="primary"
                  icon="mdi-chart-pie"
                  :title="$t('dashboard-ceo-products-sales')"
                  class="px-4 py-3 chart-title"
                >
                  <chartist
                    v-if="computeSoldInterviews"
                    class="sales-chart"
                    :data="vc_pie.data"
                    :options="vc_pie.options"
                    type="Pie"
                    style="max-height: 250px;"
                  />

                  <div v-if="!computeSoldInterviews" style="max-height: 250px;">
                    Nessuna vendita
                  </div>

                  <v-divider class="ma-3" />

                  <div class="px-3">
                    <div
                      class="body-2 text-uppercase grey--text font-weight-bold mb-3"
                    >
                      Legenda
                    </div>

                    <v-row align="center" class="ma-0">
                      <v-avatar class="mr-1" color="#2196fc" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-single")
                      }}</span>
                      <v-avatar class="mr-1" color="#ffc107" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-triple")
                      }}</span>
                      <v-avatar class="mr-1" color="#F44336" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-allsocial")
                      }}</span>
                    </v-row>
                  </div>
                </base-material-card>
              </v-col>

              <v-col cols="12" lg="4" sm="6">
                <base-material-card
                  id="vc_pie"
                  color="orange"
                  icon="mdi-chart-bar"
                  :title="$t('dashboard-ceo-products-sales-bar')"
                  class="px-4 py-3 chart-title"
                >
                  <chartist
                    v-if="computeSoldInterviews"
                    class="sales-bar-chart"
                    :data="vc_bar.data"
                    :options="vc_bar.options"
                    type="Bar"
                    style="max-height: 250px;"
                  />

                  <div v-if="!computeSoldInterviews" style="max-height: 250px;">
                    Nessuna vendita
                  </div>

                  <v-divider class="ma-3" />

                  <div class="px-3">
                    <div
                      class="body-2 text-uppercase grey--text font-weight-bold mb-3"
                    >
                      Legenda
                    </div>

                    <v-row align="center" class="ma-0">
                      <v-avatar class="mr-1" color="#2196fc" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-single")
                      }}</span>
                      <v-avatar class="mr-1" color="#ffc107" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-triple")
                      }}</span>
                      <v-avatar class="mr-1" color="#F44336" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-allsocial")
                      }}</span>
                    </v-row>
                  </div>
                </base-material-card>
              </v-col>
              <v-col cols="12" lg="4" sm="6">
                <base-material-card
                  id="vc_pie"
                  color="orange"
                  icon="mdi-chart-bar"
                  :title="$t('dashboard-ceo-products-sales-numbers')"
                  class="px-4 py-3 chart-title"
                >
                  <chartist
                    v-if="computeSoldInterviews"
                    class="sales-bar-chart"
                    :data="vc_barn.data"
                    :options="vc_barn.options"
                    type="Bar"
                    style="max-height: 250px;"
                  />

                  <div v-if="!computeSoldInterviews" style="max-height: 250px;">
                    Nessuna vendita
                  </div>

                  <v-divider class="ma-3" />

                  <div class="px-3">
                    <div
                      class="body-2 text-uppercase grey--text font-weight-bold mb-3"
                    >
                      Legenda
                    </div>

                    <v-row align="center" class="ma-0">
                      <v-avatar class="mr-1" color="#2196fc" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-single")
                      }}</span>
                      <v-avatar class="mr-1" color="#ffc107" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-triple")
                      }}</span>
                      <v-avatar class="mr-1" color="#F44336" size="12" />
                      <span class="mr-3 font-weight-light">{{
                        $t("dashboard-ceo-product-allsocial")
                      }}</span>
                    </v-row>
                  </div>
                </base-material-card>
              </v-col>
            </v-row>
          </div>
          <!--
      <v-row>
        <v-col cols="12" sm="6" lg="6" >
        <base-material-chart-card
          :data="salesChart.data"
          :options="salesChart.options"
          color="success"
          type="Line"
        >
        <h4 class="card-title font-weight-light mt-2 ml-2">
            {{$t('dashboard-graph-invoices')}}
          </h4>
         </base-material-chart-card>
        </v-col>

        <v-col cols="12" sm="6" lg="6" >
        <base-material-chart-card
          :data="analisysChart.data"
          :options="analisysChart.options"
          color="orange"
          type="Line"
        >
         <h4 class="card-title font-weight-light mt-2 ml-2">
           {{$t('dashboard-graph-analisys')}}
          </h4>
         </base-material-chart-card>
        </v-col>
    </v-row>
<v-row>
   
        <v-col cols="12" sm="6" lg="12" >
          <base-material-card color="error" icon="mdi-alert" inline class="px-5 py-3">
          <template v-slot:after-heading>
          <div class="display-1 font-weight-light card-header">{{$t('dashboard-stuck-customers')}}</div>
        </template>
       <div class='vertical-spacer'/>
         <v-data-table hide-default-header hide-default-footer :headers="worst_customers_headers" :items="dashboardState.items.worst_customers.results">
         <template v-slot:item.median="{ item }">
           {{item.average ? $options.filters.toCurrency(item.average) : 0}}
         </template>
         <template v-slot:item.last90="{ item }">
           {{item['3_months_gross'] ? $options.filters.toCurrency(item['3_months_gross']) : 0}}
         </template>
         <template v-slot:item.index="{ item }">
           {{item.average && item['3_months_gross'] ? $options.filters.toCurrency(item['3_months_gross']/item.average*100.0) + "%" : '0.00%'}}
         </template>
        </v-data-table>

        </base-material-card>
        </v-col>
  
        <v-col cols="12" sm="6" lg="6" >
        <base-material-card color="primary" icon="mdi-account-multiple" inline class="px-5 py-3">
          <template v-slot:after-heading>
          <div class="display-1 font-weight-light card-header">{{$t('dashboard-top-customers')}}</div>
        </template>
        <div class='vertical-spacer'/>

        <v-data-table hide-default-header hide-default-footer :headers="top_revenue_headers" :items="dashboardState.items.top_revenue.results">
         <template v-slot:item.total_revenue="{ item }">
           {{item.total_revenue ? $options.filters.toCurrencySymbol(item.total_revenue) : 0.00}}
         </template>
        </v-data-table>

        </base-material-card>
        </v-col>

        <v-col v-if="!filters.filterByAgentId" cols="12" sm="6" lg="6" >
        <base-material-card color="success" icon="mdi-account" inline class="px-5 py-3">
          <template v-slot:after-heading>
          <div class="display-1 font-weight-light card-header">{{$t('dashboard-top-agents')}}</div>
        </template>

        <div class='vertical-spacer'/>

        <v-data-table hide-default-header hide-default-footer :headers="top_agents_headers" :items="dashboardState.items.top_agents.results">
         <template v-slot:item.total_revenue="{ item }">
           {{item.total_revenue ? $options.filters.toCurrencySymbol(item.total_revenue): 0.00}}
         </template>
         <template v-slot:item.first_name="{ item }">
           {{item.first_name + " " + item.last_name}}
         </template>
        </v-data-table>

        </base-material-card>
        </v-col>

        <v-col cols="12" sm="6" lg="12" >
          <base-material-card color="orange" icon="mdi-flask" inline class="px-5 py-3">
          <template v-slot:after-heading>
          <div class="display-1 font-weight-light card-header">{{$t('dashboard-top-analisys')}}</div>
        </template>
        <div class='vertical-spacer'/>

        <v-data-table hide-default-header hide-default-footer :headers="top_analisys_headers" :items="dashboardState.items.top_analisys.results">
         <template v-slot:item.total_analisys="{ item }">
           {{item.total_analisys ? item.total_analisys : 0}}
         </template>
        </v-data-table>

        </base-material-card>
        </v-col>
      </v-row>
      -->
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import dashboardManager from "../apis/dashboard";
import FiltersBar from "../components/FiltersBar";

export default {
  data() {
    return {
      fetchingTwilioBalance: false,
      twilioBalance : null,
      analisysChart: {
        data: {
          labels: [],
          series: []
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            fillHoles: true
          }),
          low: 0,
          high: 0, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        percent: 0,
        ascending: true
      },

      salesChart: {
        data: {
          labels: [],
          series: [[]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            fillHoles: true
          }),
          low: 0,
          high: 0, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        percent: 0,
        ascending: true
      },

      vc_pie: {
        data: {
          series: []
        },
        options: {
          labelInterpolationFnc: value => `${value}%`,
          donut: true,
          donutWidth: 60,
          donutSolid: true,
          startAngle: 270,
          showLabel: true
        }
      },

      vc_bar: {
        data: {},
        options: {
          distributeSeries: true
        }
      },

      vc_barn: {
        data: {},
        options: {
          distributeSeries: true
        }
      },

      live_pie: {
        data: {
          series: []
        },
        options: {
          labelInterpolationFnc: value => `${value}%`,
          donut: true,
          donutWidth: 60,
          donutSolid: true,
          startAngle: 270,
          showLabel: true
        }
      },

      live_bar: {
        data: {},
        options: {
          distributeSeries: true
        }
      },

      live_barn: {
        data: {},
        options: {
          distributeSeries: true
        }
      },

      loadingDashboard: true,
      loadingFilters: true,
      dashboardState: {},
      filters: {
        filterByPeriod: 8,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByRadio: null,
        filterByScheduler: null,
        filterByType: null
      }
    };
  },

  computed: {
    computeStatsCols() {
      if (this.$store.state.hasRight("56")) return "3";
      else return "4";
    },

    filterByPeriodText() {
      return staticData.filtersPeriods()[this.filters.filterByPeriod].name;
    },

    computeSoldInterviews() {
      return this.dashboardState.items.purchased_interviews.results[0].count.toString();
    },

    computeTotalInterviews() {
      return this.dashboardState.items.total_interviews.results[0].count.toString();
    },

    computeTwilioBalance() {
      return this.twilioBalance
        ? this.twilioBalance.balance + " " + this.twilioBalance.currency
        : "";
    },

    computeSoldRatio() {
      let sold = this.dashboardState.items.purchased_interviews.results[0]
        .count;
      let total = this.dashboardState.items.total_interviews.results[0].count;
      if (sold == 0 || total == 0) return "0%";
      let ratio = (sold / total) * 100.0;
      return ratio.toFixed(2) + " %";
    },

    computeGrossValue() {
      let totalRevenue = this.dashboardState.items.gross_value.results[0].total;
      return this.$options.filters.toCurrencySymbol({
        value: totalRevenue,
        currency: "EUR"
      });
    }
  },

  watch: {
    filters: {
      handler() {
        this.fetchFinancialDashboard();
      },
      deep: true
    }
  },

  components: {
    FiltersBar
  },

  mounted() {
    this.fetchingTwilioBalance = true;
    this.fetchTwilioBalance()
      .then(res => {
        this.fetchingTwilioBalance = false;
        this.twilioBalance = res;
      })
      .catch(err => {
        console.log(err);
      });
  },

  methods: {
    fetchTwilioBalance() {
      return new Promise((resolve, reject) => {
        dashboardManager
          .fetchTwilioBalance()
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
      this.fetchFinancialDashboard();
    },

    fetchFinancialDashboard() {
      this.loadingDashboard = true;
      this.analisys = [];
      let ctx = Object.assign({}, this.filters);
      dashboardManager
        .fetchFinancialDashboard(ctx)
        .then(results => {
          if (!results) {
            this.loadingDashboard = false;
            return;
          }
          this.dashboardState = results;

          let vc_allsocial_sold = this.dashboardState.items
            .allsocial_video_purchased.results[0].count;
          let vc_basic_sold = this.dashboardState.items.basic_video_purchased
            .results[0].count;
          let vc_pro_sold = this.dashboardState.items.pro_video_purchased
            .results[0].count;
          let vc_allsocial_count = this.dashboardState.items
            .allsocial_video_count.results[0].count;
          let vc_basic_count = this.dashboardState.items.basic_video_count
            .results[0].count;
          let vc_pro_count = this.dashboardState.items.pro_video_count
            .results[0].count;
          let vc_sold = vc_allsocial_sold + vc_basic_sold + vc_pro_sold;
          let vc_total = vc_allsocial_count + vc_basic_count + vc_pro_count;

          let live_allsocial_sold =
            this.dashboardState.items[
              "live-all-social-medical-rm_video_purchased"
            ].results[0].count +
            this.dashboardState.items["live-all-social_video_purchased"]
              .results[0].count +
            this.dashboardState.items["live-social-rm_video_purchased"]
              .results[0].count;

          let live_basic_sold =
            this.dashboardState.items["live-single-medical_video_purchased"]
              .results[0].count +
            this.dashboardState.items["live-single_video_purchased"].results[0]
              .count;

          let live_pro_sold =
            this.dashboardState.items["pro_video_purchased"].results[0].count +
            this.dashboardState.items["live-triple-day_video_purchased"]
              .results[0].count +
            this.dashboardState.items["live-triple_video_purchased"].results[0]
              .count;

          let live_allsocial_count =
            this.dashboardState.items["live-all-social-medical-rm_video_count"]
              .results[0].count +
            this.dashboardState.items["live-all-social_video_count"].results[0]
              .count +
            this.dashboardState.items["live-social-rm_video_count"].results[0]
              .count;

          let live_basic_count =
            this.dashboardState.items["live-single-medical_video_count"]
              .results[0].count +
            this.dashboardState.items["live-single_video_count"].results[0]
              .count;

          let live_pro_count =
            this.dashboardState.items["pro_video_count"].results[0].count +
            this.dashboardState.items["live-triple-day_video_count"].results[0]
              .count +
            this.dashboardState.items["live-triple_video_count"].results[0]
              .count;

          let live_sold = live_allsocial_sold + live_basic_sold + live_pro_sold;
          let live_total =
            live_allsocial_count + live_basic_count + live_pro_count;

          if (vc_sold == 0) {
            this.vc_pie.data.series = [];
            this.vc_bar.data.series = [];
            this.vc_bar.data.labels = ["", "", ""];
          } else {
            this.vc_pie.data.series = [
              { value: ((vc_basic_sold / vc_sold) * 100.0).toFixed(2) },
              { value: ((vc_pro_sold / vc_sold) * 100.0).toFixed(2) },
              { value: ((vc_allsocial_sold / vc_sold) * 100.0).toFixed(2) }
            ];

            this.vc_bar.data.series = [
              { value: ((vc_basic_sold / vc_total) * 100.0).toFixed(2) },
              { value: ((vc_pro_sold / vc_total) * 100.0).toFixed(2) },
              { value: ((vc_allsocial_sold / vc_total) * 100.0).toFixed(2) }
            ];

            this.vc_bar.data.labels = [
              ((vc_basic_sold / vc_total) * 100.0).toFixed(2) + "%",
              ((vc_pro_sold / vc_total) * 100.0).toFixed(2) + "%",
              ((vc_allsocial_sold / vc_total) * 100.0).toFixed(2) + "%"
            ];

            this.vc_barn.data.series = [
              { value: vc_basic_sold },
              { value: vc_pro_sold },
              { value: vc_allsocial_sold }
            ];

            this.vc_barn.data.labels = [
              vc_basic_sold,
              vc_pro_sold,
              vc_allsocial_sold
            ];
          }

          if (live_sold == 0) {
            this.live_pie.data.series = [];
            this.live_bar.data.series = [];
            this.live_bar.data.labels = ["", "", ""];
          } else {
            this.live_pie.data.series = [
              { value: ((live_basic_sold / live_sold) * 100.0).toFixed(2) },
              { value: ((live_pro_sold / live_sold) * 100.0).toFixed(2) },
              { value: ((live_allsocial_sold / live_sold) * 100.0).toFixed(2) }
            ];

            this.live_bar.data.series = [
              { value: ((live_basic_sold / live_total) * 100.0).toFixed(2) },
              { value: ((live_pro_sold / live_total) * 100.0).toFixed(2) },
              {
                value: ((live_allsocial_sold / live_total) * 100.0).toFixed(2)
              }
            ];

            this.live_bar.data.labels = [
              ((live_basic_sold / live_total) * 100.0).toFixed(2) + "%",
              ((live_pro_sold / live_total) * 100.0).toFixed(2) + "%",
              ((live_allsocial_sold / live_total) * 100.0).toFixed(2) + "%"
            ];

            this.live_barn.data.series = [
              { value: live_basic_sold },
              { value: live_pro_sold },
              { value: live_allsocial_sold }
            ];

            this.live_barn.data.labels = [
              live_basic_sold,
              live_pro_sold,
              live_allsocial_sold
            ];
          }

          this.loadingDashboard = false;
        })
        .catch(err => {
          this.loadingDashboard = false;
          console.log(err);
        });
    }
  }
};
</script>

<style>
.sales-chart {
  margin-top: 10px;
}

.sales-chart .ct-chart-donut .ct-label {
  fill: white;
  font-size: 16px;
}

.sales-chart .ct-chart-donut .ct-series-a {
  stroke: #2196fc;
}

.sales-chart .ct-chart-donut .ct-series-a .ct-slice-donut-solid {
  fill: #2196fc;
}

.sales-chart .ct-chart-donut .ct-series-b {
  stroke: #ffc107;
}

.sales-chart .ct-chart-donut .ct-series-b .ct-slice-donut-solid {
  fill: #ffc107;
}

.sales-chart .ct-chart-donut .ct-series-c {
  stroke: #f44336;
}

.sales-chart .ct-chart-donut .ct-series-c .ct-slice-donut-solid {
  fill: #f44336;
}

.sales-bar-chart {
  margin-top: 10px;
}

.sales-bar-chart .ct-bar {
  stroke-width: 60px;
}

.sales-bar-chart .ct-series-a .ct-bar {
  stroke: #2196fc;
}

.sales-bar-chart .ct-series-b .ct-bar {
  stroke: #ffc107;
}

.sales-bar-chart .ct-series-c .ct-bar {
  stroke: #f44336;
}
</style>
