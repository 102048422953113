<template>
<div>
<SpeakerDashboard v-if="$store.state.hasRight('55') && !$store.state.isAdmin()"/>
<FinancialDashboard v-if="$store.state.hasRight('53')"/>
</div>
</template>

<script>
import FinancialDashboard from "./FinancialDashboard"
import SpeakerDashboard from "./SpeakerDashboard"

export default {
  data() {
    return {
      
    }
  },
  components :{
    FinancialDashboard,
    SpeakerDashboard
  }
}
</script>